import React from 'react'
import Layout from "../components/Layout"
import SEO from '../components/seo'
import TermlyPolicyScript from "../components/TermlyPolicyScript";

const Terms = () => (
    <>
      <Layout>
        <SEO title="Terms | Academic Info" />

        <h1>
          Terms
        </h1>

        <TermlyPolicyScript terms />
      </Layout>
    </>
);

export default Terms

import React, { useEffect } from 'react'

const TermlyPolicyScript = ({ privacy, terms }) => {

  const termlyDivId = 'termly-jssdk';

  let dataId = '';

  /*
  Privacy and Terms Termly-policy-scripts are the same, except for data-id property.
  We use a single component for both cases.
   */
  if (!!privacy) {
    dataId = '80fb3ca1-ff22-4467-9ff7-e26b86247900';
  }
  else if (!!terms) {
    dataId = '328aa2af-de49-44ac-9b48-ab33f0379a97';
  }

  // Converted Termly-embed-policy script.
  const termlyLoadTerms = (d, s, id) => {
    var js, tjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://app.termly.io/embed-policy.min.js";
    tjs.parentNode.insertBefore(js, tjs);
  };

  useEffect(()=> {
    termlyLoadTerms(document,'script', termlyDivId);

    // On component unmount:
    return () => {
      // Remove the generated iFrame, otherwise it will not be refreshed on other termly policy pages.
      document.getElementById(termlyDivId).remove();
    }
  },[]);

  return (
      <div name="termly-embed" data-id={dataId} data-type="iframe" />
  );
};

export default TermlyPolicyScript
